<template>
  <div class="result app_content">
    <div class="warp_body">
      <div class="result_body">
        <div class="resultContent">
          <div>
            <van-icon name="checked" color="#1afa29" size="130px"/>
          </div>
          <div>订单提交成功</div>
        </div>
        <div class="resultButton">
          <div class="resultButtonVan">
            <van-button plain size="normal" to="/">返回</van-button>
            <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" size="normal" to="MyInfo">我的订单</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import router from "../router";


export default {
  name: 'Result',
  components: {},

  setup() {
    onBeforeMount(() => {
      console.log("-------------------")
      methodsMap.getUserType()
    });
    const data = reactive({},
    )

    const methodsMap = {
      getUserType: () => {

      },
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">
.app_content {
  .warp_body {
    .result_body {
      height: 100%;
      background-color: #FFFFFF;
      position: relative;

      .resultContent {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translateX(-50%);
        //text-align: center;
        //margin-top: 100px;
        div {
          text-align: center
        }
      }

      .resultButton {
        position: absolute;
        top: 58%;
        width: 100%;

        &Van {
          display: flex;
          justify-content: space-evenly;

          .van-button {
            width: 100px;
          }
        }


      }
    }
  }
}
</style>
